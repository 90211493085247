export default {
  computed: {
    hostURL() {
      return window.chatviConfig.hostURL;
    },
    vapidPublicKey() {
      return window.chatviConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.chatviConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.chatviConfig.isEnterprise === 'true';
    },
    enterprisePlanName() {
      // returns "community" or "enterprise"
      return window.chatviConfig?.enterprisePlanName;
    },
  },
};
